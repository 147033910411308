<template>
    <div class="textupload" :class="{ 'activetextUpload': show }">
        <div class="textuploadBox">
            <p class="title">文本上传</p>
            <div class="item" v-for="(item, x) in list" :key="x" :class="{ 'disableItem': item.isDisable }" v-loading="item.loading" element-loading-spinner="el-icon-loading" element-loading-background="rgba(255, 255, 255, 0.2)">
                <el-upload class="img-uploader" action="#" :show-file-list="false" :http-request="(response) => uploadimg(response, x)">
                    <div class="txt">{{ item.name }}</div>
                </el-upload>
            </div>
        </div>
        <!-- close -->
        <div class="closebox" @click="openDraw">
            <i class="el-icon-close"></i>
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    props: {},
    data() {
        return {
            show: false,
            list: [
                { name: "PDF / Word / TXT", isDisable: false, loading: false },
                { name: "上传录音", isDisable: false, loading: false },
                { name: "上传图片", isDisable: true, loading: false },
            ],
        };
    },
    created() { },
    mounted() { },
    watch: {},
    computed: {},
    methods: {
        openDraw() {
            this.show = !this.show
            if(!this.show){
                this.$emit('close')
            }
        },
        // 上传
        uploadimg(item,index){
            var that = this
            if(index==0){
                // 上传pdf,word,txt
                var skuList = 'text/plain,application/pdf,application/vnd.ms-powerpoint,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                // 限制格式
                var isSku = skuList.includes(item.file.type)
                // 限制大小
                var isSize = item.file.size / 1024 / 1024 <= 10;
                if (!isSku) {
                    that.$errMsg('上传文件只能是 PDF,WORD,TXT 文件!');
                }
                if (!isSize) {
                    that.$errMsg('上传文件大小不能超过 10MB!');
                }
                if (isSku && isSize) {
                    that.list[index].loading = true
                    let newFile = new FormData()
                    newFile.append('file', item.file);
                    that.$audioApi.ttsCreateReadText(newFile).then(res => {
                        that.list[index].loading = false
                        if (res.code == 1000) {
                            that.$emit('txt',res.result)
                            // 关闭
                            that.openDraw()
                        } else {
                            that.$errMsg(res.message)
                        }
                    })
                }
            }else if(index==1){
                // 上传录音
                var skuList = 'audio/wav'
                // 限制格式
                var isSku = skuList.includes(item.file.type)
                // 限制大小
                var isSize = item.file.size / 1024 / 1024 <= 10;
                if (!isSku) {
                    that.$errMsg('上传文件只能是 WAV 文件!');
                }
                if (!isSize) {
                    that.$errMsg('上传文件大小不能超过 10MB!');
                }
                if (isSku && isSize) {
                    that.list[index].loading = true
                    let newFile = new FormData()
                    newFile.append('file', item.file);
                    that.$audioApi.ttsCreateReadText(newFile).then(res => {
                        that.list[index].loading = false
                        if (res.code == 1000) {
                            that.$emit('txt',res.result)
                            // 关闭
                            that.openDraw()
                        } else {
                            that.$errMsg(res.message)
                        }
                    })
                }
            }
        },
    },
};
</script>
<style lang="scss" scoped>
.textupload {
    position: absolute;
    width: 320px;
    left: 0;
    right: 0;
    margin: 0 auto;
    bottom: 0;
    background: #2b3036;
    height: fit-content;
    box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.69);
    border-radius: 10px;
    border: 1px solid #6e757e;
    opacity: 0;
    transition: all 0.3s ease;
    z-index: -1;

    .textuploadBox {
        padding: 20px 30px;

        .title {
            font-size: 15px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #ffffff;
            text-align: center;
            margin-bottom: 21px;
        }

        .item {
            cursor: pointer;
            line-height: 45px;
            background: #3c4148;
            border-radius: 4px;
            border: 1px solid #838383;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            text-align: center;
            margin-bottom: 20px;
            user-select: none;

            &:last-child {
                margin-bottom: 0;
            }
        }
        .img-uploader{
            width: 100%;
            height: 100%;
            ::v-deep .el-upload{
                display: block;
            }
            .txt{
                color: #ffffff;
            }
        }

        .disableItem {
            cursor: no-drop;
            .txt{
                color: #999999;
            }
        }
    }

    .closebox {
        position: absolute;
        top: 20px;
        right: 20px;

        i {
            color: #fff;
            cursor: pointer;

            &:hover {
                color: #007aff;
            }
        }
    }
}

.activetextUpload {
    transition: all 0.3s ease;
    opacity: 1;
    bottom: 36%;
    z-index: 9;
}</style>
