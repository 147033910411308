import { pinyin } from "pinyin-pro";
export const CREATEAUDIO = {
    data() {
        return {
            // 
            pinyinShow:false,
            // 保存得时间
            saveTime: '',
            // 光标的位置
            selection: null,
            // 文本
            inittxt: "",
            // 坐标
            offset: {
                top: 0,
                left: 0,
            },
            // 鼠标选中得内容
            currentEvent: null,
            // 自定义和多音字标识框
            controlsShow: {
                inputbox: false,
                multitpinyinbox: false,
            },
            // 自定义和多音字标识框输入或者选中得值
            keyword: "",
            // 光标位置
            cursorPosition: null,
            // 插入得时间
            delaytime: 0,

            j:0
        };
    },
    mounted() {
        var self = this;
        self.selection = window.getSelection();
        document.onclick = function (e) {
            let arr = ["editbox", "el-input__inner"];
            if (!arr.includes(e?.target?.className)) {
                self.closeRightMenu();
            }
        };
    },
    computed: {
        globalStyle() {
            if(this.checkRightMenuShow){
                return {
                    "--left": `${this.offset.left}px` || 0,
                    "--top": `${this.offset.top + 30 - this.$refs.editor.scrollTop}px` || 0,
                };
            }else{
                return {
                    "--left": `${this.offset.left}px` || 0,
                    "--top": `${this.offset.top + 30}px` || 0,
                };
            }
        },

        // 检测右键菜单的显示
        checkRightMenuShow() {
            return this.currentEvent?.tagName == "SPAN" && this.currentEvent.className?.includes("tag");
        },
        // 检测右键菜单多音字的显示
        checkMultitpinyinShow: {
            get() {
                return (
                    this.currentEvent?.className.includes("multitpinyin") &&
                    this.multitpinyinList?.length > 1
                );
            },
            set(value) {
                // this.checkMultitpinyinShow = value
            },
        },
        // 检测右侧多音字的列表
        multitpinyinList() {
            return this.currentEvent?.getAttribute("data-pinyin")?.split(",");
        },
        // 最外层的样式
        showRight() {
            return this.currentEvent.className?.includes("delay")
        }
    },
    methods: {
        checkTxt(){
            if(this.pinyinShow){
                this.cancelPinyin()
            }else{
                this.checkPinyin()
            }
        },
        // 读音检查
        checkPinyin(){
            var doc = document.getElementById("editor").cloneNode(true)
            var txtHtml = doc.innerHTML
            if(txtHtml==''){
                this.$errMsg('请输入文本')
                return
            }
            if(txtHtml.includes('span')){
                var regValue = /(<span\s?[^\>]*>.*?<\/span>)/gi
                var arr = txtHtml.split(regValue)
                var allTxt = ''
                for(var i=0;i<arr.length;i++){
                    if(arr[i].includes('span')){
                        allTxt = allTxt+arr[i]
                    }else{
                        var html = []
                        var str = arr[i].split("");
                        str.map((item) => {
                            let pyArr = pinyin(item, { multiple: true, type: "array" });
                            let obj = {
                                txt: item,
                                spell: pyArr,
                                template: "",
                            }
                            html.push(obj);
                        })
                        allTxt = allTxt+this.formatShow(html)
                    }
                }
                this.inittxt = allTxt
            }else{
                var html = []
                var arr = txtHtml.split("");
                arr.map((item) => {
                    let pyArr = pinyin(item, { multiple: true, type: "array" });
                    let obj = {
                        txt: item,
                        spell: pyArr,
                        template: "",
                    }
                    html.push(obj);
                })
                this.inittxt = this.formatShow(html)
            }
            this.pinyinShow = !this.pinyinShow
        },
        // 取消读音检查
        cancelPinyin(){
            var doc = document.getElementById("editor").cloneNode(true)
            var txtHtml = doc.innerHTML
            if(txtHtml==''){
                this.pinyinShow = false
                return
            }
            var spans = doc.querySelectorAll("span")
            if(spans.length){
                for (let i = 0; i < spans.length; i++) {
                    if (!spans[i].getAttribute("data-spell") && !spans[i].className.includes("delay")) {
                        // 没有设置节点
                        var node = document.createTextNode(spans[i].firstChild.nodeValue);
                        doc.replaceChild(node, spans[i])
                    }
                }
                this.inittxt = doc.innerHTML
                this.pinyinShow = false
                return
            }else{
                this.pinyinShow = false
                return
            }
        },
        // 处理文本
        formatShow(arr) {
            if (arr.length > 0) {
                arr.forEach(item => {
                    // 如果包含两个以上的拼音
                    if (item.spell.length > 1) {
                        item.template = `<span class='multitpinyin tag' contenteditable='false' data-pinyin='${item.spell}'>${item.txt}</span>`;
                    } else {
                        item.template = item.txt;
                    }
                });
                // 替换其中的数字
                var reg = /(\d+)/g;
                return arr
                    .map((item) => {
                        return item.template;
                    })
                    .join("")
                    .replace(
                        reg,
                        `<span class='multitnumber tag' contenteditable='false'>$1</span>`
                    );
            } else {
                return "";
            }
        },
        // 设置当前的选中的内容
        handlePaste(e) {
            this.currentEvent = e.target.tagName == "I" ? e.target.parentElement : e.target;
            this.offset.top = this.currentEvent.offsetTop;
            this.offset.left = this.currentEvent.offsetLeft;
        },
        // 设置部分内容的展示
        setShow(type) {
            for (let key in this.controlsShow) {
                this.controlsShow[key] = false;
            }
            this.controlsShow[type] = true;
        },
        // 设置拼音的读音
        setSpell(item) {
            if (item) {
                this.currentEvent.setAttribute("data-spell", item);
                this.createChildEdit("i", "multitpinyinI", item);
                this.closeRightMenu();
            } else {
                this.$warMsg('请输入替换的文本')
            }
        },
        // 关闭菜单的弹窗
        closeRightMenu() {
            this.currentEvent = null;
            this.setShow(" ");
            this.keyword = "";
        },
        // 创建 子元素 当选中的时候
        createChildEdit(tagname, classname, value) {
            const child = this.currentEvent.querySelector("i");
            if (child) {
                child.innerHTML = value;
            } else {
                let obj = {
                    class: classname,
                };
                this.createChild(tagname, obj, value, this.currentEvent);
            }
        },
        // 创建子元素所有情况
        createChild(tagname, classnameList, value, doc) {
            var element = document.createElement(tagname);
            element.innerHTML = value;
            for (const key in classnameList) {
                element.setAttribute(key, classnameList[key]);
            }
            doc.appendChild(element);
        },
        // 删除子元素
        deleteChild() {
            var textnode = document.createTextNode(
                this.currentEvent.firstChild.nodeValue
            );
            this.$refs.editor.replaceChild(textnode, this.currentEvent);
            this.closeRightMenu();
        },
        mousedEvent(){
            this.j = new Date().getTime()
        },
        // 鼠标键抬起
        mousedownEvent(event) {
            if(new Date().getTime() - this.j < 200) return
            if (event.target?.className?.includes("delay")) return;
            if (event.button == 0) {
                let span = document.querySelectorAll(".tag");
                for (let i = 0; i < span.length; i++) {
                    if (this.selection.containsNode(span[i])) {
                        return false;
                    }
                }
                setTimeout(() => {
                    if (this.selection.toString().length > 0) {
                        // 选择多音字只能选择一个字符
                        this.selectionTextAddClass();
                    }
                }, 100);
            }
        },
        getRange() {
            var range = new Range(window.document);
            var sel = window.getSelection();
            if (sel && sel.rangeCount) {
                var firstRange = sel.getRangeAt(0);
                var lastRange = sel.getRangeAt(sel.rangeCount - 1);
                range.setStart(firstRange.startContainer, firstRange.startOffset);
                range.setEnd(lastRange.endContainer, lastRange.endOffset);
            }
            return range;
        },
        // 选中的内容添加class
        selectionTextAddClass() {
            if(!this.pinyinShow) return   //  未检测时 不可标记
            let obj = {};
            if (this.selection.toString().length == 1) {
                obj['data-pinyin'] = pinyin(this.selection.toString(), {
                    multiple: true,
                    type: "array",
                });
            }
            var range = this.selection.getRangeAt(0);
            var newNode = this.generateElement("span", this.selection.toString(), {
                class: "multitpinyin tag",
                contenteditable: "false",
                ...obj
            });
            // this.selection.removeAllRanges();
            range.deleteContents();
            range.insertNode(newNode);
        },
        // 添加标签到页面中
        generateElement(tagName, value, obj = {}) {
            var node = document.createElement(tagName);
            node.innerText = value;
            if (Object.keys(obj).length > 0) {
            for (const key in obj) {
                node.setAttribute(key, obj[key]);
            }
            }
            return node;
        },
        // 失去焦点的时候获取光标的位置
        blurBtn() {
            this.cursorPosition = this.selection.getRangeAt(0);
        },
        // 插入停顿
        delayselect(value) {
            if (!this.cursorPosition) {
                this.$warMsg('请先选择要插入停顿的位置')
                return;
            }
            this.delaytime = value;
            this.createDelay();
        },
        // 插入停顿
        createDelay() {
            var element = document.createElement("span");
            element.setAttribute("class", "delaypause");
            element.setAttribute("contenteditable", "false");
            element.setAttribute("value", this.delaytime);
            element.innerHTML = this.delaytime + "S";
            this.cursorPosition.insertNode(element);
            this.selection.removeAllRanges();
        },
        // 合成/试听=提交的数据
        synthesis() {
            let doc = document.getElementById("editor").cloneNode(true);
            let spans = doc.querySelectorAll("span");
            var node;
            for (let i = 0; i < spans.length; i++) {
                if (spans[i].getAttribute("data-spell")) {
                    if (spans[i].getAttribute("data-pinyin")) {
                        var pinyin = spans[i].getAttribute("data-pinyin")
                        if (pinyin.includes(spans[i].getAttribute("data-spell"))) {
                            // 这个为多音字
                            node = this.formatHtml(1, spans[i]);
                        } else {
                            // 这个微替换的文本
                            node = this.formatHtml(2, spans[i]);
                        }
                        doc.replaceChild(node.childNodes[0], spans[i]);
                    } else {
                        node = this.formatHtml(2, spans[i]);
                        doc.replaceChild(node.childNodes[0], spans[i]);
                    }
                } else {
                    // 包含是否包含停顿
                    if (spans[i].className.includes("delay")) {
                        // 为停顿的节点
                        node = this.formatHtml(3, spans[i]);
                        doc.replaceChild(node.childNodes[0], spans[i]);
                    } else {
                        // 为普通的节点
                        node = this.formatHtml(4, spans[i]);
                        doc.replaceChild(node, spans[i]);
                    }
                }
            }
            return doc.innerHTML
        },
        // 保存
        saveBtn() {
            var that = this
            that.audioLoading = true
            var params = {
                content: that.removeHtml(),
                speed_rate: that.rightSettingForm.speed,
                volume: that.rightSettingForm.volume,
                pitch: that.rightSettingForm.intonation,
                sample_rate: that.rightSettingForm.rate,
                tts_list_id: that.modelInfo.id,
                tts_list_name: that.modelInfo.name,
                name: that.name,
                avatar: that.imageUrl,
                order_source: 1,
                id: that.id
            }
            that.$audioApi.ttsCreate(params).then(res => {
                that.audioLoading = false
                if (res.code == 1000) {
                    that.saveTime = that.$utils.currentSaveTime() + ' 已保存'
                    that.$succMsg(res.message)
                } else {
                    that.$errMsg(res.message)
                }
            })
        },
        // 合成
        syntheticAudio(){
            var that = this
            var doc = document.getElementById("editor").cloneNode(true)
            if(doc.innerHTML==''){
                this.$errMsg('请输入文本')
                return
            }
            that.audioLoading = true
            var params = {
                content: that.removeHtml(),
                content_trans: that.synthesis(),
                speed_rate: that.rightSettingForm.speed,
                volume: that.rightSettingForm.volume,
                pitch: that.rightSettingForm.intonation,
                sample_rate: that.rightSettingForm.rate,
                tts_list_id: that.modelInfo.id,
                tts_list_name: that.modelInfo.name,
                name: that.name,
                avatar: that.imageUrl,
                order_source:1,
                id: that.id,
            }
            that.$audioApi.ttsCreateSynthesis(params).then(res => {
                that.audioLoading = false
                if (res.code == 1000) {
                    that.$succMsg('音频合成已提交,请耐心等待!')
                    that.$store.dispatch('tabsArr/delTabList')
                    that.$router.push({
                        path: '/creation/audioDetails/' + that.id,
                        query: {
                            name: that.name
                        }
                    })
                } else {
                    that.$errMsg(res.message)
                }
            })
        },
        // 移除没有设置的节点
        removeHtml(){
            var doc = document.getElementById("editor").cloneNode(true)
            var spans = doc.querySelectorAll("span");
            for (let i = 0; i < spans.length; i++) {
                if (!spans[i].getAttribute("data-spell") && !spans[i].className.includes("delay")) {
                    // 没有设置节点
                    var node = document.createTextNode(spans[i].firstChild.nodeValue);
                    doc.replaceChild(node, spans[i])
                }
            }
            return doc.innerHTML
        },
        // 格式化传递时候要展示的html字符串
        formatHtml(type, target) {
            // 1为替换多音字，2为替换文本， 3 为停顿的节点 4，为普通的节点
            var node = "";
            if (type == 1) {
                let pinyinArr = target.getAttribute("data-pinyin")
                let pinyinSpell = target.getAttribute("data-spell")
                let currentPinyin = target.firstChild.nodeValue
                // 当前的声调
                let index = pinyinArr.split(',').findIndex(item => item == pinyinSpell)
                // 获取数字类型的多音字
                let pinyinArrNum = pinyin(currentPinyin, { toneType: 'num', type: 'array', multiple: true, })
                let pinyinTxt = pinyinArrNum[index]
                let pinyin_left = pinyinTxt.slice(0,pinyinTxt.length-1)
                let pinyin_right = pinyinTxt.slice(pinyinTxt.length-1,pinyinTxt.length)
                if(pinyin_right==0){
                    pinyin_right = 5
                }
                node = document.createElement("phoneme");
                node.innerHTML = `<phoneme alphabet="py" ph="${pinyin_left+' '+pinyin_right}">${currentPinyin}</phoneme>`;
            }
            if (type == 2) {
                node = document.createElement("sub")
                node.innerHTML = `<sub alias="${target.getAttribute('data-spell')}">${target.firstChild.nodeValue}</sub>`
            }
            if (type == 3) {
                node = document.createElement("break")
                node.innerHTML = `<break time="${target.getAttribute("value") * 1000}ms"/>`
            }
            if (type == 4) {
                node = document.createTextNode(target.firstChild.nodeValue);
            }
            return node
        },
    },
};
