<template>
    <div class="rightsetting" :class="{ 'activeshow': drawer }">
        <div class="rightsettingbox" v-show="mainShow">
            <p class="title"><i class="el-icon-close" @click="openDraw"></i>语音设置</p>
            <div class="boxmain scroll_bar_x">
                <div class="item">
                    <div class="item-title">模型</div>
                    <div class="item-con">
                        <el-input v-model="modelInfo.name" disabled placeholder="请输入内容" size="small"></el-input>
                    </div>
                </div>
                <div class="item">
                    <div class="item-title" style="margin-bottom: 16px">音频效果</div>
                    <div class="item-progress">
                        <div class="item-progress-one">
                            <div class="one-l">语速</div>
                            <div class="one-r">{{ rightSettingForm.speed }}</div>
                        </div>
                        <div class="item-progress-two">
                            <el-slider v-model="rightSettingForm.speed" :min="-20" :max="20" :show-tooltip="false"></el-slider>
                        </div>
                    </div>
                    <div class="item-progress">
                        <div class="item-progress-one">
                            <div class="one-l">音量</div>
                            <div class="one-r">{{ rightSettingForm.volume }}</div>
                        </div>
                        <div class="item-progress-two">
                            <el-slider v-model="rightSettingForm.volume" :min="-20" :max="20" :show-tooltip="false"></el-slider>
                        </div>
                    </div>
                    <div class="item-progress">
                        <div class="item-progress-one">
                            <div class="one-l">语调</div>
                            <div class="one-r">{{ rightSettingForm.intonation }}</div>
                        </div>
                        <div class="item-progress-two">
                            <el-slider v-model="rightSettingForm.intonation" :min="-20" :max="20" :show-tooltip="false"></el-slider>
                        </div>
                    </div>
                </div>
                <div class="item selectitem">
                    <div class="item-title">采样率</div>
                    <div class="item-select">
                        <el-select v-model="rightSettingForm.rate" placeholder="请选择" size="small">
                            <el-option v-for="item in sampleRate" :key="item.value" :label="item.label" :value="item.value">
                            </el-option>
                        </el-select>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    props: {
        sampleRate: {
            type: Array,
            default: () => [],
        },
        modelInfo: {
            type: Object,
            default: () => { },
        },
        rightSettingForm: {
            type: Object,
            default: () => { },
        },
    },
    data() {
        return {
            drawer: false,
            mainShow: false,
        };
    },
    created() { },
    mounted() { },
    watch: {
        drawer(value) {
            if (value) {
                setTimeout(() => {
                    this.mainShow = value
                }, 200);
            } else {
                this.mainShow = value
            }
        }
    },
    computed: {},
    methods: {
        // 打开
        openDraw() {
            this.drawer = !this.drawer
            if(!this.drawer){
                this.$emit('close')
            }
        },
    },
};
</script>
<style lang="scss" scoped>
.rightsetting {
    position: absolute;
    top: 88px;
    right: 111px;
    width: 0;
    height: calc(100% - 231px - 88px);
    background: #2e363f;
    box-shadow: -10px 1px 11px 0px rgba(0, 0, 0, 0.33);
    overflow: hidden;
    transition: all 0.3s ease;

    .rightsettingbox {
        height: 100%;

        .title {
            font-size: 15px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #ffffff;
            padding: 20px;
            user-select: none;
            border-bottom: 1px solid #454a50;
            width: 100%;

            i {
                color: #fff;
                cursor: pointer;
                margin-right: 20px;

                &:hover {
                    color: #007aff;
                }
            }
        }

        .boxmain {
            padding: 20px;
            overflow-y: scroll;
            height: calc(100% - 68px);

            .item {
                margin-bottom: 20px;

                .item-title {
                    font-size: 12px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #c1c1c1;
                }

                .item-con {
                    margin-top: 16px;

                    ::v-deep .el-input__inner {
                        background: #272c32;
                        border-radius: 4px;
                        border: none;
                        color: #fff;
                    }
                }

                .item-progress {
                    .item-progress-one {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        .one-l {
                            font-size: 14px;
                            font-family: PingFangSC-Regular, PingFang SC;
                            font-weight: 400;
                            color: #ffffff;
                        }

                        .one-r {
                            width: 70px;
                            font-size: 14px;
                            font-weight: 400;
                            padding: 4px 10px;
                            color: #fff;
                            text-align: right;
                            border-radius: 4px;
                            background: #272c32;
                        }
                    }
                }
            }

            .selectitem {
                margin-bottom: 0;
                display: flex;
                align-items: center;

                .item-title {
                    width: 40px;
                }

                .item-select {
                    margin-left: 10px;
                    width: 147px;
                    user-select: none;

                    ::v-deep .el-input__inner {
                        background: #272c32;
                        border-radius: 4px;
                        border: none;
                        color: #fff;
                    }

                    :v-deep .el-select-dropdown {
                        background: #272c32;
                        color: #fff;
                    }
                }
            }
        }
    }
}

.activeshow {
    transition: all 0.3s ease;
    width: 344px;
}
</style>
