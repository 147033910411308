export const AUDIOCONTRALS = {
    data() {
        return {
            audioLoading:false,
            // 音频对象
            audioCtx: null,
            // 加载
            audioStatus: 3, // 1:缓冲 2：正在播放 3:停止播放
            // 播放
            audioInfo: {
                currentTime: 0, // 当前的播放的时间
                duration: 0, // 音频的总的时长 
            },
            // 当前播放进度
            playTime:0,
        };
    },
    mounted() { },
    destroyed() { },
    methods: {
        // 初始化音频播放
        initAudio(blobUrl) {
            this.audioCtx = new Audio(URL.createObjectURL(blobUrl));
            // this.audioCtx = new Audio(blobUrl);
            // 开始播放
            this.audioCtx.addEventListener("play", () => {
                // console.log('可以播放')
                this.audioStatus = 2
            });
            //   暂停
            this.audioCtx.addEventListener("pause", () => {
                this.audioStatus = 3
            });
            //   播放结束
            this.audioCtx.addEventListener("ended", () => {
                this.audioStatus = 3
                this.playTime = 0
                this.audioInfo.currentTime = 0
            });
            //   缓冲下载
            this.audioCtx.addEventListener("progress", (e) => {
                // console.log(e)
            });
            //   开始加载
            this.audioCtx.addEventListener("waiting", () => {
                this.audioStatus = 1
            });
            //   加载完成
            this.audioCtx.addEventListener("canplay", (e) => {
                this.audioStatus = 2
                this.audioInfo.duration = e.target.duration;
            });
            // 播放时间的舰艇
            this.audioCtx.addEventListener("timeupdate", (e) => {
                this.audioInfo.currentTime = e.target.currentTime
                this.timeBtn(e.target.currentTime.toFixed(2))
            });
            this.playMusic();
        },
        // 试听
        startPlay() {
            var that = this
            var doc = document.getElementById("editor").cloneNode(true)
            if(doc.innerHTML==''){
                this.$errMsg('请输入文本')
                return
            }
            that.audioLoading = true
            that.audioStatus = 1
            var params = {
                content: that.synthesis(),
                speed_rate: that.rightSettingForm.speed,
                volume: that.rightSettingForm.volume,
                pitch: that.rightSettingForm.intonation,
                sample_rate: that.rightSettingForm.rate,
                tts_list_id: that.modelInfo.id,
                tts_list_name: that.modelInfo.name,
            }
            that.$audioApi.ttsCreateAudition(params).then(res => {
                that.audioLoading = false
                that.audioStatus = 3
                if(!res) return
                // 创建音频
                if(that.audioCtx != null){
                    that.audioCtx.pause()
                    that.audioCtx = null
                }
                // that.initAudio('https://ws.stream.qqmusic.qq.com/C400000TiuWK3P3l62.m4a?guid=4220211900&vkey=542C8C9CC82E88E87B240FC2D93AED74A7CB143DC11F81C119827548590DD0016DD69B94836E03F350E228B63CF3D912DDA3815360EEED47&uin=&fromtag=123032')
                that.initAudio(res)
            })
        },
        // 播放
        playMusic() {
            if(!this.audioCtx) return
            this.audioCtx.play();
        },
        // 暂停
        pauseMusic() {
            if(!this.audioCtx) return
            this.audioCtx.pause();
        },
        // 重新加载
        stopMusic() {
            if(!this.audioCtx) return
            this.audioCtx.load();
        },
        // 点击进度条
        changeSlider(val){
            var ratio = (val/100).toFixed(8)
            this.audioCtx.currentTime = (this.audioInfo.duration*ratio).toFixed(8)*1
            this.audioCtx.play();
        },
        // 处理时间
        timeBtn(time){
            var ratio = (time/this.audioInfo.duration).toFixed(8)
            this.playTime = (100*ratio).toFixed(8)*1
        },
    },
};
