<template>
    <div class="modal" :class="{ activeshow: drawer }">
        <div class="modalbox" v-show="mainShow">
            <!-- 顶部的标题 -->
            <div class="titlebox">
                <span>模型</span>
                <i class="el-icon-close" @click="openDraw"></i>
            </div>
            <!-- 搜索框 -->
            <div class="searchbox">
                <el-input v-model="modelTxt" placeholder="输入关键词搜索" size="small" suffix-icon="el-icon-search" 
                    @keyup.enter.native="$event.target.blur()" @blur="modelInput"></el-input>
            </div>
            <!-- 下方的内容列表 -->
            <div class="mainbox">
                <div class="mainbox-l scroll_bar_x" v-show="leftShow">
                    <div class="item" v-for="(item, x) in navList" :key="x" @click="selectLeft(item)"
                        :class="{ leftMenuActive: item.selected == 1 }">
                        {{ item.name }}
                    </div>
                </div>
                <div class="mainbox-r" :class="leftShow?'':'mainbox-r-ss'" v-loading="leftLoading" element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)">
                    <div class="r_data" v-if="list.length==0">
                        <empty-data img='no_img_b.png' text='暂无数据'></empty-data>
                    </div>
                    <div class="mainbox-r-box scroll_bar_x" v-else>
                        <div class="itembox" :class="item.selected==1?'itemBoxActive':''" v-for="(item, mx) in list" :key="mx" >
                            <div class="item" @click="selectedBtn(item,'',1)">
                                <div class="item-l">
                                    <img :src="item.avatar" alt="" />
                                </div>
                                <div class="item-r">
                                    <div class="r-one">
                                        <div class="r-one-l">{{ item.name }}</div>
                                        <div class="r-one-r" v-if="item.children && item.children.length">
                                            <!-- 存在子集 -->
                                            <div class="audioicon" v-if="item.show" @click.stop="openChildren(item)">
                                                <img src="@/assets/images/createaudio/icon9.png" alt="" />
                                            </div>
                                            <div class="audioicon" v-else @click.stop="openChildren(item)">
                                                <img src="@/assets/images/createaudio/icon15.png" alt="" />
                                            </div>
                                        </div>
                                        <div class="r-one-r" v-else>
                                            <!-- loading -->
                                            <div class="audioicon" v-show="item.audioStatus == 1">
                                                <i class="el-icon-loading loadingicon"></i>
                                            </div>
                                            <!-- 播放 -->
                                            <div class="audioicon" v-show="item.audioStatus == 3" @click.stop="changeBoxPlay(item,'play')" >
                                                <img src="@/assets/images/createaudio/icon10.png" alt="" />
                                            </div>
                                            <!-- 暂停 -->
                                            <div class="audioicon" v-show="item.audioStatus == 2" @click.stop="changeBoxPlay(item,'pause')">
                                                <img src="@/assets/images/createaudio/icon8.png" alt="" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="r-two one-txt-cut">
                                        <!-- <span class="tag">中文</span> -->
                                        {{ item.label }}
                                    </div>
                                </div>
                            </div>
                            <!-- 子元素 -->
                            <div v-if="item.children && item.children.length && item.show">
                                <div class="itemchildren" :class="ite.selected==1?'itemChildrenActive':''" v-for="(ite, cx) in item.children" :key="cx" @click="selectedBtn(item,ite,2)">
                                    <div class="itemchildren-l">
                                        <img :src="ite.avatar" alt="" />
                                    </div>
                                    <div class="itemchildren-c one-txt-cut">
                                        <p class="name">{{ite.name}}</p>
                                        <p class=" c-two one-txt-cut">
                                            <!-- <span class="tag">中文</span> -->
                                            {{ ite.label }}
                                        </p>
                                    </div>
                                    <div class="itemchildren-r">
                                        <!-- loading -->
                                        <div class="audioicon" v-show="ite.audioStatus == 1">
                                            <i class="el-icon-loading loadingicon"></i>
                                        </div>
                                        <!-- 播放 -->
                                        <div class="audioicon" v-show="ite.audioStatus == 3" @click.stop="changeBoxPlay(ite,'play')">
                                            <img src="@/assets/images/createaudio/icon10.png" alt="" />
                                        </div>
                                        <!-- 暂停 -->
                                        <div class="audioicon" v-show="ite.audioStatus == 2" @click.stop="changeBoxPlay(ite,'pause')">
                                            <img src="@/assets/images/createaudio/icon8.png" alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import EmptyData from '@/components/empty_data/empty_data.vue'//空数据
export default {
    components: {EmptyData},
    props: {
        // 分类
        navList: {
            type: Array,
            default: [],
        },
        // 模型列表
        list: {
            type: Array,
            default: [],
        },
    },
    data() {
        return {
            drawer: false,
            mainShow: false,
            modelTxt: "",
            leftShow:true,
            // 创建一个音频播放器
            audioModel:null,
            // 
            leftLoading:false,
        };
    },
    created() { },
    mounted() { },
    watch: {
        drawer(value) {
            if (value) {
                setTimeout(() => {
                    this.mainShow = value
                }, 200);
            } else {
                this.mainShow = value
            }
        }
    },
    computed: {},
    methods: {
        // 加载
        getLoading(){
            this.leftLoading = !this.leftLoading
        },
        // 搜索
        modelInput(){
            if(!this.modelTxt){
                this.leftShow = true
                return
            }
            this.leftShow = false
            var obj = {
                keyword: this.modelTxt
            }
            this.$emit('nav',obj)
        },
        // 打开和关闭弹窗
        openDraw() {
            this.drawer = !this.drawer
            if(!this.drawer){
                this.$emit('close')
            }
        },
        // 切换
        selectLeft(item){
            var obj = {
                category_id: item.id
            }
            this.$emit('nav',obj)
        },
        // 选中
        selectedBtn(item,ite,type){
            if(item.children && item.children.length && type==1){
                this.$set(item,'show',!item.show)
                this.$forceUpdate()
            }else{
                var obj = {
                    type:type,
                    item:item,
                    ite:ite
                }
                this.$emit('change',obj)
                this.$forceUpdate()
            }
        },
        // 存在子集展开
        openChildren(item){
            this.$set(item,'show',!item.show)
            this.$forceUpdate()
        },
        // 播放
        changeBoxPlay(item,type){
            var that = this
            if(type=='play'){
                if(that.audioModel){
                    that.audioModel.pause()
                    that.audioModel = null
                }
                that.audioModel = new Audio(item.audio_url)
                //   开始加载
                that.audioModel.addEventListener("waiting", () => {
                    that.$set(item,'audioStatus',1)
                    that.$forceUpdate()
                });
                //   加载完成
                that.audioModel.addEventListener("canplay", () => {
                    that.$set(item,'audioStatus',2)
                    that.$forceUpdate()
                });
                // 开始播放
                that.audioModel.addEventListener("play", () => {
                    that.$set(item,'audioStatus',2)
                    that.$forceUpdate()
                });
                //   暂停
                that.audioModel.addEventListener("pause", () => {
                    that.$set(item,'audioStatus',3)
                    that.$forceUpdate()
                });
                // 播放结束
                that.audioModel.addEventListener("ended", () => {
                    that.$set(item,'audioStatus',3)
                    that.$forceUpdate()
                });
                that.$emit('play')
                that.audioModel.play()
            }else{
                that.audioModel.pause()
            }
        },
    },
};
</script>
<style lang="scss" scoped>
.modal {
    position: absolute;
    top: 88px;
    left: 100px;
    width: 0;
    height: calc(100% - 110px);
    background: #2b3036;
    transition: all 0.3s ease;
    overflow: hidden;
    z-index: 1002;

    .modalbox {
        width: 100%;
        height: 100%;
        padding: 20px 0;

        .titlebox {
            padding: 0 15px 20px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid #42474e;

            span {
                font-size: 15px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #ffffff;
            }

            i {
                color: #fff;
                cursor: pointer;

                &:hover {
                    color: #007aff;
                }
            }
        }

        .searchbox {
            margin-top: 15px;
            padding: 0 15px;

            ::v-deep .el-input__inner {
                background-color: #3c4148;
                border: none;
                color: #fff;
            }
        }

        .mainbox {
            margin-top: 15px;
            display: flex;
            height: calc(100% - 90px);
            padding-left: 15px;

            .mainbox-l {
                width: 90px;
                background-color: #3c4148;
                border-radius: 4px 0 0 4px;
                overflow-y: scroll;

                .item {
                    font-size: 13px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #ffffff;
                    text-align: center;
                    padding: 11px 0;
                    cursor: pointer;
                    transition: all 0.3s ease;
                }

                .leftMenuActive {
                    background: rgba(45, 74, 241, 0.29);
                    position: relative;
                    transition: all 0.3s ease;

                    &::before {
                        content: "";
                        position: absolute;
                        width: 3px;
                        height: 40px;
                        background: #2d4af1;
                        top: 0;
                        left: 0;
                    }
                }
            }

            .mainbox-r {
                flex: 1;
                margin-left: 4px;
                .r_data{
                    width: 100%;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    ::v-deep .empty_data{
                        width: 100%;
                        img{
                            width: 160px;
                            height: 100px;
                        }
                    }
                }
                .mainbox-r-box {
                    overflow-y: scroll;
                    height: 100%;

                    .itembox {
                        margin-bottom: 10px;
                    }

                    

                    .item {
                        cursor: pointer;
                        display: flex;
                        align-items: center;
                        border: 1px solid #3c4148;
                        background: #3c4148;
                        border-radius: 4px;
                        padding: 14px 12px;
                        width: 226px;

                        .item-l {
                            min-width: 40px;

                            img {
                                width: 40px;
                                height: 40px;
                                border-radius: 50%;
                                overflow: hidden;
                            }
                        }

                        .item-r {
                            width: 162px;
                            padding-left: 10px;

                            .r-one {
                                display: flex;
                                align-items: center;
                                justify-content: space-between;

                                .r-one-l {
                                    font-size: 14px;
                                    font-family: PingFangSC-Regular, PingFang SC;
                                    font-weight: 400;
                                    color: #ffffff;
                                }

                                .r-one-r {
                                    .audioicon {
                                        width: 17px;
                                        height: 16px;
                                        border-radius: 50%;
                                        background-color: #385dfe;
                                        cursor: pointer;
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;

                                        .loadingicon {
                                            color: #fff;
                                            font-size: 12px;
                                        }
                                    }
                                }
                            }

                            .r-two {
                                font-size: 12px;
                                font-family: PingFangSC-Regular, PingFang SC;
                                font-weight: 400;
                                color: #999999;
                                margin-top: 6px;

                                .tag {
                                    display: inline-block;
                                    font-size: 12px;
                                    font-family: PingFangSC-Regular, PingFang SC;
                                    font-weight: 400;
                                    color: #ffffff;
                                    background: #ff9237;
                                    border-radius: 2px;
                                    padding: 0 5px;
                                }
                            }
                        }
                    }

                    .itemBoxActive .item {
                        border: 1px solid #385dfe;
                    }
                    
                    .itemchildren {
                        cursor: pointer;
                        display: flex;
                        align-items: center;
                        border: 1px solid #242627;
                        background: #242627;
                        border-radius: 4px;
                        padding: 14px 12px;
                        width: 226px;
                        margin-bottom: 10px;

                        &:first-child {
                            margin-top: 10px;
                        }

                        .itemchildren-l {
                            min-width: 40px;

                            img {
                                width: 40px;
                                height: 40px;
                                border-radius: 50%;
                                overflow: hidden;
                            }
                        }

                        .itemchildren-r {
                            .audioicon {
                                width: 17px;
                                height: 16px;
                                border-radius: 50%;
                                background-color: #385dfe;
                                cursor: pointer;
                                display: flex;
                                align-items: center;
                                justify-content: center;

                                .loadingicon {
                                    color: #fff;
                                    font-size: 12px;
                                }
                            }
                        }

                        .itemchildren-c {
                            flex: 1;
                            padding-left: 10px;

                            .name {
                                font-size: 14px;
                                font-family: PingFangSC-Regular, PingFang SC;
                                font-weight: 400;
                                color: #FFFFFF;
                            }

                            .c-two {
                                font-size: 12px;
                                font-family: PingFangSC-Regular, PingFang SC;
                                font-weight: 400;
                                color: #999999;
                                margin-top: 6px;

                                .tag {
                                    display: inline-block;
                                    font-size: 12px;
                                    font-family: PingFangSC-Regular, PingFang SC;
                                    font-weight: 400;
                                    color: #ffffff;
                                    background: #ff9237;
                                    border-radius: 2px;
                                    padding: 0 5px;
                                }
                            }
                        }
                    }
                    .itemChildrenActive{
                        border: 1px solid #385dfe;
                    }
                }
            }
            .mainbox-r-ss{
                width: 100%;
                margin-left: 0;
                .item{
                    width: 320px !important;
                    .item-r{
                        width: 280px !important;
                    }
                }
                .itemchildren{
                    width: 320px !important;
                }
            }
        }
    }
}

.activeshow {
    transition: all 0.3s ease;
    width: 350px;
}
</style>
